import React, { useState } from "react";
import Container from "@mui/material/Container";
import LoginImage from "../../assets/images/login.png";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Forget from "../../assets/images/forgot.png";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Login() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Container maxWidth="xl">
        <div className="loginmain">
          <div className="login_image_center">
            <img src={LoginImage} alt="login" className="logo_image_style" />
          </div>
          <div className="login_center_div">
            <div className="logo_login_center">
              <img src={LoginImage} alt="logo" className="logo_imgIlogon" />
            </div>
            <h3>Welcome to CRM</h3>

            <div className="login_textfield_center">
              <TextField
                id="standard-password-input"
                label="Email"
                type="email"
                autoComplete="current-password"
                variant="standard"
                className="login_textfield"
              />
              <TextField
                id="standard-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="standard"
                className="login_textfield"
              />
            </div>
            <div className="login_check_box">
              <div className="check_vox">
                <Checkbox {...label} />
                <p>I agree to all terms and conditions </p>
              </div>
              <p className="forget" onClick={handleOpen}>
                Forgot Password
              </p>
            </div>
            <div className="login_center">
              <Link to="/home" className="link_style">
                <button className="login_button">Login</button>
              </Link>
            </div>
            <Link to="/signup" className="link_style">
              <p className="new_account">New here? Create an account</p>
            </Link>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal_style">
            <div className="forget_text">
              <h5>Forget password</h5>
            </div>
            <div className="modal_data">
              <div>
                <img src={Forget} alt="forget" className="img" />
              </div>
              <div className="forget_sec_div">
                <TextField
                  id="standard-password-input"
                  label="Email"
                  type="email"
                  autoComplete="current-password"
                  variant="standard"
                  className="login_textfield"
                />
                <button className="forget_button">Send Email</button>
              </div>
            </div>
          </Box>
        </Modal>
      </Container>
    </div>
  );
}
