import React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import Headre from "../../components/Header/Headre";
import { BarChart } from "@mui/x-charts/BarChart";
import User from "../../assets/images/user.jpg";
import GroupIcon from "@mui/icons-material/Group";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { PieChart } from "@mui/x-charts/PieChart";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const data1 = [
  { label: "Group A", value: 400 },
  { label: "Group B", value: 300 },
  { label: "Group C", value: 300 },
  { label: "Group D", value: 200 },
];

const data2 = [
  { label: "A1", value: 100 },
  { label: "A2", value: 300 },
  { label: "B1", value: 100 },
  { label: "B2", value: 80 },
  { label: "B3", value: 40 },
  { label: "B4", value: 30 },
  { label: "B5", value: 50 },
  { label: "C1", value: 100 },
  { label: "C2", value: 200 },
  { label: "D1", value: 150 },
  { label: "D2", value: 50 },
];
function createData(name, calories, fat) {
  return { name, calories, fat };
}
function createData2(name, calories, fat, paisa) {
  return { name, calories, fat, paisa };
}

const rows = [
  createData("Lead", 1, 100),
  createData("Contacted", 1, 200),
  createData("Qualified", 3, 133),
  createData("Proposal made", 4, 200),
  createData("Won", 8, 0),
  createData("Lost", 3, 0),
  createData("Fridge", 0, 0),
];
const rows2 = [
  createData2("Thomas", 100000, 13000, 140000),
  createData2("Laura", 75000, 175000, 20000),
  createData2("Michael", 75000, 120000, 150000),
];

export default function Home() {
  return (
    <div className="home_main">
      <Dashboard />
      <div>
        <Headre />
        <div className="home_div_style">
          <div>
            <div className="bar_chat">
              <BarChart
                xAxis={[
                  {
                    id: "barCategories",
                    data: [
                      "bar A",
                      "bar B",
                      "bar C",
                      "bar d",
                      "bar e",
                      "bar f",
                      "bar g",
                      "bar h",
                      "bar i",
                    ],
                    scaleType: "band",
                  },
                ]}
                series={[
                  {
                    data: [2, 5, 3, 2, 3, 5, 6, 5, 1],
                  },
                ]}
                width={"400"}
                height={"300"}
                className="char_home_style"
              />
            </div>
            <div className="bar_chat_2">
              {/* <p>Hello</p> */}
              <div>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Target</TableCell>
                        <TableCell align="right">Won</TableCell>
                        <TableCell align="right">Expected</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows2.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            <div className="user_name_style">
                              <img
                                src={User}
                                alt="user"
                                className="home_user"
                              />
                              <p>{row.name}</p>
                            </div>
                          </TableCell>
                          <TableCell align="right">{row.calories} $</TableCell>
                          <TableCell align="right">{row.fat} $</TableCell>
                          <TableCell align="right">{row.paisa} $</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="bar_chat_2">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Stage</TableCell>
                      <TableCell align="right"># opportunities</TableCell>
                      <TableCell align="right">
                        Conversion to next stage
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.calories}%</TableCell>
                        <TableCell align="right">{row.fat}%</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="">
            <div className="bar_chat_3">
              <p>hello</p>
              <div className="graph_home">
                <div className="">
                  <PieChart
                    series={[
                      {
                        innerRadius: 0,
                        outerRadius: 30,
                        data: data1,
                      },
                      {
                        innerRadius: 60,
                        outerRadius: 80,
                        data: data2,
                      },
                    ]}
                    width={"300"}
                    height={"200"}
                    legend={{ hidden: true }}
                  />
                </div>
                <div className="circle_home_bar">
                  <p>Hello</p>
                  <p>Hello</p>
                </div>
              </div>
            </div>
            <div className="bar_chat_3">
              <div className="home_div_class_center">
                <div className="circle_div">
                  <GroupIcon fontSize="large" color="primary" />
                </div>
                <h3>123,237</h3>
              </div>
            </div>
            <div className="bar_chat_3">
              <div className="home_div_class_center">
                <div className="circle_div">
                  <ShowChartIcon fontSize="large" color="primary" />
                </div>
                <h3>$625</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
