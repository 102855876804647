import React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import Headre from "../../components/Header/Headre";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function Addlist() {
  return (
    <div className="list_div_show">
      <Dashboard />
      <div>
        <Headre />
        <div className="list_main">
          <div className="list_heading_name">
            <h4>Add List</h4>
          </div>
          <div className="text_field_style">
            <TextField
              id="standard-password-input"
              label="Name"
              type="text"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Company Name"
              type="text"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Contact Name"
              type="text"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Stage"
              type="text"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Value"
              type="number"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Probability"
              type="number"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Expected Revenue"
              type="number"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
             <TextField
              id="standard-password-input"
              label="Expected Closer"
              type="number"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expected Close date"
                className="login_textfield"
              />
            </LocalizationProvider>

            <TextField
              id="standard-password-input"
              label="Team member"
              type="text"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Progress to Won"
              type="number"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Contact Email"
              type="email"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Last interacted on"
                className="login_textfield"
              />
            </LocalizationProvider>
            <TextField
              id="standard-password-input"
              label="Next Step"
              type="text"
              autoComplete="current-password"
              variant="outlined"
              className="login_textfield"
            />
          </div>
          <div className="add_button">
            <button className="button_style">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}
