import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Singup from "../pages/SingUp/Singup";
import Login from "../pages/Login/Login";
import Pagenotfound from "../pages/404/404page";
import List from "../pages/List/List";
import Addlist from "../pages/Addlist/Addlist";
import Edituser from "../pages/Edituser/Edituser";
import User from "../pages/User/User";

const Main = () => {
  return (
    <Router>
      <div className="wrapper">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/signup" element={<Singup />} />
          <Route path="/home" element={<Home />} />
          <Route path="/list" element={<List />} />
          <Route path="/addlist" element={<Addlist />} />
          <Route path="/edituser" element={<Edituser />} />
          <Route path="/user" element={<User />} />
          <Route path="/*" element={<Pagenotfound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default Main;
