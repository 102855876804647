/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import Headre from "../../components/Header/Headre";
import TextField from "@mui/material/TextField";
import UserImage from "../../assets/images/profile.jpg";

export default function User() {
  const [image, setImage] = useState("");
  const handleChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  

  return (
    <div className="list_div_show">
      <Dashboard />
      <div>
        <Headre />
        <div className="list_main">
          <div className="list_heading_name">
            <h4>User Profile</h4>
          </div>
          <div className="user_image_style">
            <div>
              <label htmlFor="upload-button">
                {image ? (
                  <img src={image} alt="dummy" className="user_image" />
                ) : (
                  <img src={UserImage} className="user_image" alt="user" />
                )}
              </label>
              <input
                type="file"
                id="upload-button"
                className="input_image"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="user_data">
            <TextField
              id="standard-password-input"
              label="Name"
              type="text"
              autoComplete="current-password"
              variant="standard"
              className="user_textfield"
              defaultValue="Akshay"
            />
            <TextField
              id="standard-password-input"
              label="Email"
              type="email"
              autoComplete="current-password"
              variant="standard"
              className="user_textfield"
              defaultValue="ak@gmail.com"
              disabled
            />
            <TextField
              id="standard-password-input"
              label="Number"
              type="number"
              autoComplete="current-password"
              variant="standard"
              className="user_textfield"
              defaultValue="9898787678"
              disabled
            />
            <TextField
              id="standard-password-input"
              label="Current Password"
              type="password"
              autoComplete="current-password"
              variant="standard"
              className="user_textfield"
            />
            <TextField
              id="standard-password-input"
              label="Change Password"
              type="password"
              autoComplete="current-password"
              variant="standard"
              className="user_textfield"
            />
          </div>
          <div className="add_button">
            <button className="button_style">Update</button>
          </div>
        </div>
      </div>
    </div>
  );
}
