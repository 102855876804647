import React from "react";
import Container from "@mui/material/Container";
import SingupImage from "../../assets/images/singup.png";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Singup() {
  return (
    <div>
      <Container maxWidth="xl">
        <div className="sign_main">
          <div className="login_image_center">
            <img
              src={SingupImage}
              alt="login"
              className="singup_logo_image_style"
            />
          </div>
          <div className="login_center_div">
            <h3>SignUp</h3>
            <div className="login_textfield_center">
              <TextField
                id="standard-password-input"
                label="Name"
                type="text"
                autoComplete="current-password"
                variant="standard"
                className="login_textfield"
              />
              <TextField
                id="standard-password-input"
                label="Email"
                type="email"
                autoComplete="current-password"
                variant="standard"
                className="login_textfield"
              />
              <TextField
                id="standard-password-input"
                label="Number"
                type="number"
                autoComplete="current-password"
                variant="standard"
                className="login_textfield"
              />
              <TextField
                id="standard-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="standard"
                className="login_textfield"
              />
              <TextField
                id="standard-password-input"
                label="Confirm Password"
                type="password"
                autoComplete="current-password"
                variant="standard"
                className="login_textfield"
              />
            </div>
            <div className="login_check_box">
              <div className="check_vox">
                <Checkbox {...label} />
                <p>I agree to all terms and conditions </p>
              </div>
            </div>
            <button className="login_button">Submit</button>
            <Link to="/" className="link_style">
              <p className="new_account">Already have an account? Log in</p>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}
