import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export default function Dashboard() {
  return (
    <div className="dashboard_div">
      <div className="dashboard_partone">
        <div className="dashboard_logo"></div>
        <div className="dashboard_center">
          <Link to="/home" className="link_url_style">
            <div className="dashboard_data">
              <p className="dashboard_title">DASHBOARD</p>
              <HomeIcon color="primary" />
            </div>
          </Link>
          <Link to="/list" className="link_url_style">
            <div className="dashboard_data">
              <p className="dashboard_title">List</p>
              <ReceiptLongIcon color="primary" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
